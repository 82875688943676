import React from 'react';
import Dots from '../../../public/home/dots.webp';
import DotsBG from '../../../public/home/dots-bg.webp';
import GDPR from '../../../public/home/gdpr.webp';
import Image from "next/future/image"


const HomePrivacy = () => {
  return (
    <>
    <div className="row g-0 d-flex">
        <div className="col-lg-7 col-md-12 col-12 col-md-6 p-md-5 p-4 help-container d-flex flex-column flex-grow-1">
            <div className="help-title mb-md-5 mb-3 d-flex flex-column">
                <h3 className="help-title">Still Wondering How <span className="theme">KonfHub</span> Can Help Your Event?</h3>
            </div>
            <div className="help-sub-text d-flex">
                Got some queries? Not sure if KonfHub is a right solution for making your event super successful? We'll be happy to help you talk to you & explore synergies. Or just book a demo to talk to our experts!
            </div>
            <div className="pt-3 d-flex flex-grow-1 align-items-md-end align-items-start flex-md-row flex-column">
                <a href="mailto:reachus@konfhub.com" target="_blank" rel="noreferrer">
                    <button className="btn btn-header mt-md-5 mt-3">
                        Get in Touch&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <i className="fas fa-chevron-circle-right arrow"></i>
                    </button>
                </a>
                <a href="https://calendly.com/konfhub/konfhub" target="_blank" rel="noreferrer">
                    <button className="btn btn-header ms-0 ms-lg-5 ms-sm-3 mt-md-5 mt-3 btn-demo">
                        Schedule Demo&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <i className="fas fa-chevron-circle-right arrow"></i>
                    </button>
                </a>

            </div>
        </div>
        <div className="col-lg-5 col-md-12 col-12 p-md-5 p-4 privacy-container d-flex flex-column flex-grow-1">
            <h4 className="privacy-title mb-md-4 mb-3 d-flex flex-column">
                <div><span className="theme">Data Privacy</span> is Our Foremost Priority</div>
            </h4>
            <div className="privacy-sub-text d-flex">
                Your events are in safe hands, and so is your data. We are GDPR compliant and give utmost importance to data privacy and security.
            </div>
            <div className="pt-5 d-flex flex-grow-1 align-items-end position-relative">
                <div className="dots position-absolute"/>
                <Image src={GDPR} width="112" height="112" alt="GDPR" className="gdpr"/>
            </div>
        </div>
    </div>
    <style>{`
        .help-container {
            font-family: 'Nunito';
            background: url(${DotsBG}) #ffffff no-repeat;
            background-size: contain;
        }

        .help-container .help-title {
            font-weight: 700;
            font-size: 50px;
            line-height: 65px;
            color: #572148;
        }

        .help-container .help-title .theme {
            color: #FB5850;
        }

        .help-container .help-sub-text {
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
            color: #6A6660;
        }

        .privacy-container {
            background: #572148;
            font-family: 'Nunito';
        }

        .privacy-container {
            font-family: 'Nunito';
        }

        .privacy-container .privacy-title {
            font-weight: 700;
            font-size: 50px;
            line-height: 65px;
            color: #FFFFFF;
        }

        .privacy-container .privacy-title .theme {
            color: #FB5850;
        }

        .privacy-container .privacy-sub-text {
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
            color: #FFFFFF;
        }

        .privacy-container .dots {
            background: url(${Dots}) no-repeat;
            width: 130px;
            height: 96px;
            top: 40px;
            left: 40px;
            background-size: cover;
        }

        .privacy-container .gdpr {
            z-index: 1;
        }

        /* SM screens */
        @media only screen and (min-width: 480px) and (max-width: 768px) {
            .help-container .help-title {
                font-size: 30px;
                line-height: 40px;
            }

            .help-container{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .privacy-container{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .help-container .help-sub-text {
                font-size: 16px;
            }

            .privacy-container .privacy-title {
                font-size: 30px;
                line-height: 40px;
            }

            .privacy-container .privacy-sub-text {
                font-size: 16px;
            }
        }

        /* XS screens */
        @media only screen and (max-width: 480px) {
            .help-container .help-title {
                font-size: 30px;
                line-height: 40px;
            }

            .help-container{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .privacy-container{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .help-container .help-sub-text {
                font-size: 16px;
            }

            .privacy-container .privacy-title {
                font-size: 30px;
                line-height: 40px;
            }

            .privacy-container .privacy-sub-text {
                font-size: 16px;
            }
        }
    `}</style>
</>
  )
}

export default HomePrivacy
